import {
    Abstract
} from './Abstract';

export class EntryExitItem extends Abstract{
    constructor(session){
        super('inventarios/EntryExitItem',session);
        this.id_item = null;
        this.id_entry_exit_warehouse = null;
        this.key_name = null;
        this.id_inventory_warehouse = null;
        this.inventory_warehouse_name = null;
        this.id_inventory_subwarehouse = null;
        this.inventory_subwarehouse_name = null;
        this.id_inventory_warehouse_destination = null;
        this.inventory_warehouse_destination_name = null;
        this.id_inventory_subwarehouse_destination = null;
        this.inventory_subwarehouse_destination_name = null;
        this.quantity = 0;
        this.unit_price = 0.00;
        this.measure_unit = null;
        this.type_movement = null;
        this.stock = 0;
    }
}