import {
    Abstract
} from './Abstract';

export class EntryExitWareHouse extends Abstract{
    constructor(session){
        super('inventarios/EntryExitWareHouse',session);
        this.date = new Date();
        this.type_movement = null;
        this.movement = null;
        this.id_request = null;
        this.comments = null;
        this.status = null;
        this.id_purchase_order = null;
        this.items = [];
    }
}